import { Controller } from 'stimulus'

export default class DurationInputController extends Controller {
  static targets = ["minutes", "hours"]

  get hoursValue() { return this.hoursTarget.value }
  get minutesValue() { return this.minutesTarget.value }

  get hours() { return this.hoursValue ? parseInt(this.hoursValue) : 0 }
  set hours(value) { return this.hoursTarget.value = value }

  get minutes() { return this.minutesValue ? parseInt(this.minutesValue) : 0 }
  set minutes(value) { return this.minutesTarget.value = value }

  connect() {
  }


  minutesChanged() {
    if (this.minutes < 0) {
      if (this.hours > 0) {
        this.hours = this.hours - 1
        this.minutes += 60
        this.minutesChanged()
      } else {
        this.minutes = 0
      }
    } else if (this.minutes > 59) {
      this.minutes -= 60
      this.hours += 1
      this.minutesChanged()
    }
  }

  hoursChanged() {
    if (this.hours < 0) {
      this.hours = 0
      this.minutes = 0
    }
  }
}