

import { library } from '@fortawesome/fontawesome-svg-core'


import { faFileAlt as fasFileAlt, faAppleAlt as fasAppleAlt, faHashtag as fasHashtag, faUser as fasUser, faComment as fasComment, faWeight as fasWeight, faLightbulb as fasLightbulb, faKey as fasKey, faPlus as fasPlus, faPencilAlt as fasPencilAlt, faTrash as fasTrash, faEllipsisV as fasEllipsisV, faCheck as fasCheck, faBold as fasBold, faItalic as fasItalic, faUnderline as fasUnderline, faStrikethrough as fasStrikethrough, faLink as fasLink, faHeading as fasHeading, faQuoteRight as fasQuoteRight, faCode as fasCode, faListUl as fasListUl, faListOl as fasListOl, faIndent as fasIndent, faOutdent as fasOutdent, faUndo as fasUndo, faRedo as fasRedo, faCamera as fasCamera, faSearch as fasSearch, faClock as fasClock, faChartLine as fasChartLine, faPlusCircle as fasPlusCircle, faCircle as fasCircle, faPencilRuler as fasPencilRuler, faPaperPlane as fasPaperPlane, faGlassCheers as fasGlassCheers, faSave as fasSave, faSync as fasSync, faMagic as fasMagic, faGripLines as fasGripLines, faReply as fasReply } from "@fortawesome/free-solid-svg-icons"





export function setup() {
  
  library.add(fasFileAlt);
  
  library.add(fasAppleAlt);
  
  library.add(fasHashtag);
  
  library.add(fasUser);
  
  library.add(fasComment);
  
  library.add(fasWeight);
  
  library.add(fasLightbulb);
  
  library.add(fasKey);
  
  library.add(fasPlus);
  
  library.add(fasPencilAlt);
  
  library.add(fasTrash);
  
  library.add(fasEllipsisV);
  
  library.add(fasCheck);
  
  library.add(fasBold);
  
  library.add(fasItalic);
  
  library.add(fasUnderline);
  
  library.add(fasStrikethrough);
  
  library.add(fasLink);
  
  library.add(fasHeading);
  
  library.add(fasQuoteRight);
  
  library.add(fasCode);
  
  library.add(fasListUl);
  
  library.add(fasListOl);
  
  library.add(fasIndent);
  
  library.add(fasOutdent);
  
  library.add(fasUndo);
  
  library.add(fasRedo);
  
  library.add(fasCamera);
  
  library.add(fasSearch);
  
  library.add(fasClock);
  
  library.add(fasChartLine);
  
  library.add(fasLightbulb);
  
  library.add(fasPlusCircle);
  
  library.add(fasCircle);
  
  library.add(fasPencilRuler);
  
  library.add(fasPaperPlane);
  
  library.add(fasGlassCheers);
  
  library.add(fasSave);
  
  library.add(fasSync);
  
  library.add(fasMagic);
  
  library.add(fasGripLines);
  
  library.add(fasReply);
  
}
