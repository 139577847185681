import { Controller } from 'stimulus'
import $ from 'jquery'

export default class RemovableController extends Controller {
  connect() {
  }

  removeElement(e) {
    e.preventDefault()

    const $el = $(this.element)
    $el.slideUp(() => $el.remove())
  }
}