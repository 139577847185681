import { Controller } from 'stimulus'
import $ from 'jquery'

let id = 0

const DESTROY_DELAY = 100

export default class SelectizeController extends Controller {
  static targets = ["input"]

  get valueField()  { return this.data.get("value") || "title" }
  get labelField()  { return this.data.get("label") || "title" }
  get searchField() { return this.data.get("search") || this.labelField }
  get sortField()   { return this.data.get("sort") || this.labelField }
  get maxItems()    { return this.data.get("max-items") ? parseInt(this.data.get("max-items")) : null }

  initialize() {
    this.reuseID = id++
  }

  connect() {
    // Needed for restoration visits, since there might still be an old selectize element in the DOM
    this.cleanupOldSelectizeElements()

    if (this.destroyTimeout) {
      this.clearDestroyTimeout()
    } else {
      this.createSelectize()
    }
  }

  disconnect() {
    // When ingredients are reordered - disconnect, and connect
    // are called in rapid succession. Therefore, we defer the
    // Selectize destruction

    this.scheduleDestroyTimeout()
  }

  // =============
  // = Selectize =
  // =============

  createSelectize() {
    const loadOptions = this.loadOptions.bind(this)

    $(this.element).selectize({
      create: true,
      persisted: false,
      plugins: [ 'remove_button'],
      valueField: this.valueField,
      labelField: this.labelField,
			searchField: this.searchField,
			sortField: this.sortField,
      maxItems: this.maxItems,
      options: [],
      load: function(query, callback) {
        this.clearOptions()
        loadOptions(query, callback)
      }
    })

    this.selectizeCreated = true
  }

  destroySelectize() {
    if (this.selectizeCreated) {
      $(this.element).selectize("destroy").show()

      this.selectizeCreated = false
    }
  }

  cleanupOldSelectizeElements() {
    $(this.element).closest(".form-group").find(".selectize-control").remove()
  }

  loadOptions(query, callback) {
    const url = this.data.get("url").replace("QUERY", window.encodeURIComponent(query))

    fetch(url, {
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((json) => callback(json))
      .catch((e) => {
        console.error("Failed to load selectize content", e)

        callback([])
      })
  }


  // ==============
  // = Destroying =
  // ==============
  scheduleDestroyTimeout() {
    this.destroyTimeout = setTimeout(() => {
      this.destroySelectize()
      this.destroyTimeout = null
    }, DESTROY_DELAY)
  }

  clearDestroyTimeout() {
    clearTimeout(this.destroyTimeout)
  }

}