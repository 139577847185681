import { Controller } from 'stimulus'
import $ from 'jquery'
import Sortable from 'sortablejs';

export default class IngredientsEditorController extends Controller {
  static targets = ["list", "item"]

  static FIELD_MATCHER = /IDENTIFIER/g

  get ingredientTemplate() { return this.data.get("ingredient-template") }
  get groupTemplate() { return this.data.get("group-template") }

  connect() {
    this.sortable = new Sortable(this.listTarget, {
      // draggable: "*[data-target=ingredients-editor.item]",
      // handle: "*[data-target=ingredients-editor.reorder]",
      animation: 150,
      ghostClass: "bg-light",
      dragClass: "bg-white",
      onSort: () => this.updatePositions()
    })
  }

  disconnect() {
    this.sortable.destroy();
  }

  // ===========
  // = Actions =
  // ===========

  addIngredient() {
    const template = this.prepareTemplate(this.ingredientTemplate)

    $(this.listTarget).append(template)
    this.updatePositions()
  }

  addGroup() {
    const template = this.prepareTemplate(this.groupTemplate)

    $(this.listTarget).append(template)
    this.updatePositions()
  }

  refreshPositions() {
    this.updatePositions()
  }

  destroyItem(e) {
    e.preventDefault()

    const $target = $(e.target)
    const $item = $target.parents("[data-target~='ingredients-editor.item']")

    this.removeItem($item)
    this.updatePositions()
  }

  // ================
  // = Item Removal =
  // ================

  removeItem($item) {
    const id = $item.find("[data-target~='ingredients-editor.id']").val()
    const isPersisted = id != ""

    if (isPersisted) {
      const $destroyInput = $item.find("[data-target~='ingredients-editor.destroyInput']")

      $destroyInput.val("1")
      $item.addClass("d-none")
    } else {
      $item.remove()
    }
  }

  // ==============
  // = Templating =
  // ==============

  prepareTemplate(template) {
    const id = this.generateFieldId()

    return template.replace(this.constructor.FIELD_MATCHER, id);
  }

  // =============
  // = Field Ids =
  // =============

  generateFieldId() {
    const now = new Date()

    return `${now.getTime()}`
  }

  // =======================
  // = Position Management =
  // =======================

  updatePositions() {
    let i = 0;
    this.itemTargets.forEach((itemTarget) => {
      const $item = $(itemTarget)

      if (!$item.is(".d-none")) {
        const $position = $item.find("[data-target~='ingredients-editor.itemPosition']")
        $position.val(i++)
      }

    })
  }
}