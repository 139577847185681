import { Controller } from 'stimulus'
import $ from 'jquery'

export default class RichTextController extends Controller {
  get $element() { return $(this.element) }
  get $trix() { return this.$element.find("trix-editor") }

  connect() {
    this.form = this.$element.closest("form")
    this.form.on("submit", () => this.formSubmitted())
  }

  formSubmitted() {
    this.$trix
      .addClass("disabled")
      .prop("contenteditable", false)
  }
}